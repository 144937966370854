@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400&family=Lato:wght@300;400;700&display=swap');
body {
  background-color        : #f1f1f8;
  margin                  : 0;
  font-family             : 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI',
                            'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
                            'Helvetica Neue', sans-serif;
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
}

code {
  font-family             : source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
